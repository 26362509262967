import React, { useState } from "react";
import { graphql } from "gatsby";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import SliderCarousel from "../components/Slider";
import SpaceMap from "../components/GoogleMap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SpaceTemplate({ data }) {
  const [isShow, setIsShow] = useState(false);
  const carouselSettings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "innerClass",
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };
  const {
    spaceGeoLocalisation,
    spaceProfileImage,
    spaceCoverPicture,
    spaceName,
    spaceDescription,
    eMail,
    openingTime,
    prix,
    prixdemiJourne,
    prixheure,
    theOfficeMcSpaceUrl,
    stateAddress,
  } = data.contentfulTheOfficeSpace;
  return (
    <React.Fragment>
      <Header />
      <section id="space-hero">
        <div className="space-hero__cover">
          <img src={spaceCoverPicture.file.url} alt="" />
        </div>
        <div className="container">
          <div className="space-detail">
            <div className="space-detail__warper">
              <SliderCarousel
                settings={carouselSettings}
                media={spaceProfileImage}
              />
              <h1 className="space-detail__title">{spaceName}</h1>
              <div className="space-detail__info">
                <div className="warper-lp">
                  {!stateAddress ? (
                    ""
                  ) : (
                    <div className="address info-content">
                      <img src="/img/icon/icons_espaces-adresse.png" alt="" />
                      <address>{stateAddress}</address>
                    </div>
                  )}
                  <div className="email info-content">
                    <img src="/img/icon/icons_espaces-mail.png" alt="" />
                    <p>{eMail ?? "contact@theoffice.digital"}</p>
                  </div>
                  {!openingTime ? (
                    ""
                  ) : (
                    <div className="hour-open info-content">
                      <img src="/img/icon/icons_espaces-time.png" alt="" />
                      <p>{openingTime}</p>
                    </div>
                  )}
                  <div className="amenities-lp">
                    <div className="amenity">
                      <div className="content">
                        <img src="/img/icon/icons_espaces-wifi.png" alt="" />
                        <p>wifi</p>
                      </div>
                    </div>
                    <div className="amenity">
                      <div className="content">
                        <img src="/img/icon/icons_espaces-imp.png" alt="" />
                        <p>Imprimante</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="amenities-and-book">
                  <div className="amenities">
                    <div className="amenity">
                      <div className="content">
                        <img src="/img/icon/icons_espaces-wifi.png" alt="" />
                        <p>wifi</p>
                      </div>
                    </div>
                    <div className="amenity">
                      <div className="content">
                        <img src="/img/icon/icons_espaces-imp.png" alt="" />
                        <p>Imprimante</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`space-detailed-info ${
                      !isShow ? "force-hidden" : ""
                    }`}
                  >
                    <div className="space-detailed-info__description">
                      <h2>Description de l’espace</h2>
                      <p>{spaceDescription.spaceDescription}</p>
                    </div>
                    <div id="space-detailed-info__location">
                      <SpaceMap
                        geo={{
                          lat: spaceGeoLocalisation.lat,
                          lng: spaceGeoLocalisation.lon,
                        }}
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => setIsShow(!isShow)}
                    className={`toggle-info ${
                      isShow ? "toggle-info__close" : ""
                    }`}
                  >
                    TOUT SAVOIR SUR CET ESPACE
                  </button>
                  <div className="book-price">
                    <div className="price-warper">
                      <span className="from">à partir de</span>
                      <h3 className="price">
                        {`${prix} DA`}
                        <sup>TTC</sup>
                      </h3>
                      <span className="time">la journée</span>
                      <a
                        href={theOfficeMcSpaceUrl || "http://theoffice.digital"}
                        target="_blank"
                        rel="noreferrer"
                        className="book-cta__lp"
                      >
                        Je Réserve
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href={theOfficeMcSpaceUrl || "http://theoffice.digital"}
                className="book-cta"
              >
                Je Réserve
              </a>
              <div
                className={`space-detailed-info lp ${
                  !isShow ? "force-hidden" : ""
                }`}
              >
                <div className="space-detailed-info__description">
                  <h2>Description de l’espace</h2>
                  <p>{spaceDescription.spaceDescription}</p>
                </div>
                <div id="space-detailed-info__location-lp">
                  <SpaceMap
                    geo={{
                      lat: spaceGeoLocalisation.lat,
                      lng: spaceGeoLocalisation.lon,
                    }}
                  />
                </div>
              </div>
              <button
                onClick={() => setIsShow(!isShow)}
                className={`toggle-info__lp ${
                  isShow ? "toggle-info__close" : ""
                }`}
              >
                TOUT SAVOIR SUR CET ESPACE
              </button>
            </div>
            <div id="trafication">
              <div className="container">
                <h1>une traficitation que vous allez adorder</h1>
                <div className="cards">
                  <div className="price-card best-offer">
                    <p className="time">heure</p>
                    <div className="price-warper">
                      <p className="price">
                        {prixheure}
                        <sup>DA</sup>
                      </p>
                    </div>
                  </div>
                  <div className="price-card">
                    <p className="time">journée</p>
                    <div className="price-warper">
                      <p className="price">
                        {prix}
                        <sup>DA</sup>
                      </p>
                    </div>
                  </div>
                  <div className="price-card">
                    <p className="time">demi-journée</p>
                    <div className="price-warper">
                      <p className="price">
                        {prixdemiJourne}
                        <sup>DA</sup>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}
export const query = graphql`
  query($slug: String!) {
    contentfulTheOfficeSpace(slug: { eq: $slug }) {
      spaceName
      eMail
      openingTime
      prix
      prixdemiJourne
      prixheure
      theOfficeMcSpaceUrl
      stateAddress
      spaceCoverPicture {
        file {
          url
        }
      }
      spaceProfileImage {
        file {
          url
        }
      }
      spaceDescription {
        spaceDescription
      }
      spaceGeoLocalisation {
        lat
        lon
      }
    }
  }
`;
