import React from "react";
import Slider from "react-slick";
export default function SliderCarousel({ media, settings }) {
  return (
    <Slider {...settings}>
      {media.map((space, index) => (
        <img key={index} src={space.file.url} alt="" />
      ))}
    </Slider>
  );
}
