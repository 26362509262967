import React from "react";
import GoogleMapReact from "google-map-react";

export default function SpaceMap({ geo }) {
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_API_KEY }}
        defaultCenter={geo}
        defaultZoom={12}
      >
        <Marker lat={geo.lat} lng={geo.lng} text="My Marker" />
      </GoogleMapReact>
    </div>
  );
}
const Marker = () => (
  <>
    <img style={{ width: "5em" }} src="/img/icon/location-circle.png" alt="" />
  </>
);
